import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getAccessToken, getUser, isLoggedIn, getNotificationUrl } from './utils/jwt';

const authLink = new ApolloLink((operation, forward) => {
    if (isLoggedIn()) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${getAccessToken()}`,
            },
        });
    }
    return forward(operation);
});

const httpLink = ApolloLink.from([authLink, new HttpLink({
    uri: process.env.REACT_APP_BACKEND_URL
})]);

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_URL,
    options: {
        reconnect: true,
        connectionParams: {
            authorization: isLoggedIn() && "Bearer " + getAccessToken(),
            user: isLoggedIn() && getUser(),
            n_url: getNotificationUrl()
        },
    }
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition'
            && definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

export default new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        addTypename: false
    }),
});
