import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Example ': 'Translate',
    },
    general: {
      'Error': 'Something went wrong!',
      'Success': 'Success!',
      'Exit': 'Exit',
      'Save': 'Save',
    },
    modalSignature: {
      'Table Sign': 'Signature',
      'Table Save': 'Save',
      'Table Quit': 'Quit',
      'Table Quantity': 'Quantity',
      'Table Product Name': 'Product Name',
      'Table UM': 'Unit Measure',
      'Table Add Material': 'Add additional material',
      'Table Error High Number': 'Please enter a number higher than 0!',
      'Table Error UM': 'Please enter a unit measure!',
      'Table Error Name': 'Please enter a name!',
      'Table Operation': 'Please choose an operation!',
      'Table Add Operation': 'Add Operation',
      'Table Problem': 'Describe Problem',
      'Quantity Car': 'Quantity in vehicle',
      'Add Material': 'Add Material',
      'Quantity Car Error': 'Insufficient quantity in car!',
    },
    login: {
      'Table Login': 'Log in',
      'Table Title': 'Sign in on the internal platform',
      'Table Email': 'Email Address',
      'Table Password': 'Password',
      'Table Forgot Password': 'Forgot Password',
      'Table Connect': 'Sign in',
      'Table Error Email': 'Email is required!',
      'Table Error Email Valid': 'Please enter a valid email!',
      'Table Error Password Valid': 'Password is required!',
      'Table Error Login Pass': 'The password entered is incorrect!',
      'Table Error Login Email': 'The email entered is incorrect!',
      'Server Error': 'Can not connect with the server',
      'Went Wrong': 'Something went wrong',
    },
    profile: {
      'Profile ': 'Profile',
      'Tab General': 'General',
      'Tab Notification': 'Notification',
      'Tab Security': 'Security',
      'Dashboard ': 'Home',
      'Account ': 'Account',
      'Table Name': 'First Name',
      'Table Last Name': 'Last Name',
      'Table Email': 'Email',
      'Table Phone': 'Phone',
      'Table Address': 'Address',
      'Table Country': 'Country',
      'Table City': 'City',
      'Table Postal Code': 'Postal Code',
      'Table Role': 'Role:',
      'Picture ': 'Remove picture',
      'Contact Info': 'Contact Information',
      'Table Theme': 'Application Theme',
      'Table Logout': 'Logout',
      'Table Settings Password': 'Password Settings',
      'Table Change': 'Change Password',
      'Table Password': 'Current Password',
      'Table New Password': 'New Password',
      'Table Confirm Password': 'Confirm New Password',
      'Password Match': 'Password must match!',
      'Password Req Current': 'Please enter your current password!',
      'Password Req New': 'Please enter your new password!',
      'Password Length': 'Password must contain at least 7 characters !',
      'Msg Success': 'Password updated!',
    },
    clientsAccounts: {
      'Table Title': 'Clients Accounts',
      'Table Administration': 'Administration',
      'Table Settings': 'Settings',
      'Table Accounts List': 'Table Clients Accounts',
      'Table List': 'Clients Accounts List',
      'Table Search': 'Search Accounts',
      'Table Name': 'Name',
      'Table Type': 'Client Type',
      'Table Phone': 'Phone Number',
      'Table Last Activity': 'Last Activity',
      'Table Status': 'Account Status',
      'Table Action': 'Actions',
      'Details Clients Account': 'Client Account Details',
      'Client Account Data': 'Client Account Data',
      'Account Data': 'Account Data',
      'Btn Back': 'Back',
      'Btn Block': 'Block Account',
      'Btn UnBlock': 'Unblock Account',
      'Table FirstName': 'FirstName',
      'Table LastName': 'LastName',
      'Table Quit': 'Quit',
      'Msg Block': 'Do you want to block the current account ?',
      'Msg Success': 'Account state has changed!',
      'Table Password': 'Password',
      'Table User Data': 'The following data for the user are',
      'Table Warning User Data': 'Warning, the data will be showed only once !',
      'Table Change Prospect Btn': 'Transform to Client',
      'Table Reset Pass Btn': 'Reset Password',
      'Msg Success Reset': 'Reset password successfully!',
    },
    services: {
      'Table Type': 'Service Type',
      'Table Company': 'Company',
      'Table Actions': 'Actions',
      'Table Button': 'Add Services',
      'Table Tab': 'Service Table',
      'Table Search': 'Search services',
      'Table Title': 'Services',
      'Table Details': 'Details',
      'Table Dashboard': 'Administration',
      'Table Nomenclatures': 'Nomenclatures',
      'Table Services': 'Services',
      'Table Edit': 'Edit Service',
      'Table Add': 'Add Service',
      'Table Name': 'Name',
      'Table Exit': 'Back',
      'Table Save': 'Save',
      'Table Link': 'Add Services',
      'Table Management': 'Management',
      'Table Title Details': 'Details Services',
      'Delete Warning': "Remove this service if he requested that, if not, please be aware that what has been deleted can't brought back.",
      'Delete Button': 'Delete',
      'Success Message': 'Service created successfully!',
      'Success Edit': 'Service updated successfully!',
      'Default operation': 'Default operation'
    },
    operations: {
      'Table Type': 'Name',
      'Table Money': 'LEI + TVA',
      'Table Actions': 'Actions',
      'Table Button': 'Add Operation',
      'Table Tab': 'Operations Table',
      'Table Search': 'Search operations',
      'Table Title': 'Operations',
      'Table Code': 'Code',
      'Table Dashboard': 'Administration',
      'Table Price': 'Price',
      'Table Service': 'Service Type',
      'Table Nomenclatures': 'Nomenclatures',
      'Table Services': 'Operations',
      'Table UM': 'Unit Measure',
      'Table Details': 'Details',
      'Table Title Details': 'Operation Details',
      'Table Name': 'Name',
      'Table Exit': 'Back',
      'Table Save': 'Save',
      'Table Edit': 'Edit Operation',
      'Table Link': 'Add Operation',
      'Table Management': 'Management',
      'Delete Warning': "Remove this operation if he requested that, if not, please be aware that what has been deleted can't brought back.",
      'Delete Button': 'Delete',
      'Msg Success': 'Operation is updated!',
      'Msg Success Add': 'Operation added!',
      'Table Error Name': 'Name is required!',
      'Table Error Code': 'Code is required!',
      'Table Error Price': 'Price is required!',
      'Table Error UnitMeasure': 'Unit Measure is required!',
      'Table Error Service Type': 'Service Type is required!',
    },
    vehicles: {
      'Table Type': 'Name',
      'Table Money': 'LEI + TVA',
      'Table Actions': 'Actions',
      'Table Button': 'Add Vehicles',
      'Table Tab': 'Vehicle Table',
      'Table Search': 'Search vehicles',
      'Table Title': 'Vehicles',
      'Table Dashboard': 'General',
      'Table Details': 'Details',
      'Table Nomenclatures': 'Nomenclatures',
      'Table Services': 'Vehicles',
      'Table Edit': 'Edit Vehicle',
      'Table Name': 'Name',
      'Table Exit': 'Back',
      'Table Save': 'Save',
      'Table Link': 'Add Vehicle',
      'Table Management': 'Management',
      'Table Title Details': 'Vehicle Details',
      'Delete Warning': "Remove this vehicle if he requested that, if not, please be aware that what has been deleted can't brought back.",
      'Delete Button': 'Delete',
      'Table Vehicle': 'Vehicle',
      'Table Driver': 'Driver',
      'Table Status': 'Status',
      'Table Vehicle Id': 'Vehicle ID',
      'Table Insurance': 'Insurance',
      'Table Add': 'Add Vehicle',
      'Table Vendor': 'Vehicle Inventory',
      'Id Product': 'Product ID',
      'Product Name': 'Designation',
      'UM ': 'UM',
      'Table Company': 'Company',
      'Quantity ': 'Quantity',
      'Table Plate': 'Număr Înmatriculare',
      'Table Owner': 'Name',
      'Table ASIG': 'Insurance',
      'Table Error Name': 'Name is required!',
      'Table Error Plate': 'Plate is required!',
      'Table Error Owner': 'Owner is required!',
      'Table Error Company': 'Company is required!',
      'Table Error ROV': 'ROV is required!',
      'Table Error ITP': 'ITP is required!',
      'Table Error ASIG': 'Insurance is required!',
      'Table Error Status': 'Status is required!',
      'Table Success Add': 'Vehicle Created!',
      'Table Success Edit': 'Vehicle Updated!',
      'Table Code': 'Cod',
      'Table Material Class': 'Material Class',
      'Table Quantity': 'Quantity',
      'Table UM': 'UM',
      'Table Product': 'Product List',
      'Table Error Vehicle Quantity': 'Quantity is required!',
      'Table Error Vehicle Company': 'Product is required!',
      'Table Material Success Add': 'Material added with success!',
      'Table Action': 'Actions',
      'Table Author': 'Author',
    },
    companies: {
      'Table Type': 'Name',
      'Table Money': 'LEI + TVA',
      'Table Actions': 'Actions',
      'Table Button': 'Add Companies',
      'Table Tab': 'Companies Table',
      'Table Search': 'Search companies',
      'Table Title': 'Companies',
      'Table Dashboard': 'Administration',
      'Table Nomenclatures': 'Nomenclatures',
      'Table Services': 'Companies',
      'Table Details': 'Details',
      'Table Title Details': 'Company Details',
      'Table Name': 'Name',
      'Table Exit': 'Back',
      'Table Save': 'Save',
      'Table Edit': 'Edit Company',
      'Table Link': 'Add Company',
      'Table Management': 'Management',
      'Table Add': 'Add Company',
      'Table Status': 'Status',
      'Delete Warning': "Remove this company if he requested that, if not, please be aware that what has been deleted can't brought back.",
      'Delete Button': 'Delete',
      'Msg Success': 'Company updated!',
      'Msg Success Add': 'Company added!',
      'Table Error Name': 'Name is required!',
      'Table Error Cui': 'Cui is required!',
      'Table Error Status': 'Status is required!',
      'Table Data': 'Date',
      'Table Hour': 'Hour',
      'Table Product Name': 'Material Name',
      'Table Author': 'Author',
      'Table Actions History': 'Actions',
      'Table Quantity': 'Quantity',
    },
    inventory: {
      'PricePQ ': 'Price / Unit Measure',
      'Table Type': 'Product ID',
      'Table Company': 'Class Product',
      'Table Actions': 'Actions',
      'Table Button': 'Add Product',
      'Table Tab': 'Inventory Table',
      'Table Search': 'Search Product',
      'Table Title': 'Inventory',
      'Table Details': 'Details',
      'Table Dashboard': 'Dashboard',
      'Table Nomenclatures': 'Administrator',
      'Table Services': 'Inventory',
      'Table Edit': 'Edit Product',
      'Table Name': 'Name',
      'Table Administrator': 'Administrator',
      'Table Add': 'Add Product',
      'Table Exit': 'Exit',
      'Table Save': 'Save',
      'Table Link': 'Add Product',
      'Table Management': 'Management',
      'Table Title Details': 'Details Inventory',
      'Delete Warning': "Remove this inventory if he requested that, if not, please be aware that what has been deleted can't brought back.",
      'Delete Button': 'Delete',
      'Table Design': 'Designation',
      'Quantity ': 'Quantity',
      'Table Class': 'Product Class',
      'Table Status': 'Status',
      'UM ': 'UM',
      'Table Export': 'Download Inventory',
      'Table Company Inventory': 'Company',
      'Table Company Vehicle': 'Inventory',
      'Table Code': 'Code',
      'Table Material Class': 'Material Category',
      'Table UM': 'Unit Measure',
      'Table Quantity': 'Quantity',
      'Table General Title': 'General',
      'Table Add Product': 'Add Product',
      'Table Success Add': 'Product created!',
      'Table Success Edit': 'Product updated!',
      'Table Date': 'Date',
      'Table Hour': 'Hour',
      'Table Name Product': 'Product Name',
      'Table Add Material Inventory': 'Add Material Quantity',
      'Table Quantity Material Inventory': 'Material Quantity',
      'Table Edit Inv': 'Edit Material',
      'Table Material Info': 'Material Data',
      'Table Import': 'Import CSV',
      'General Inventory': 'General Inventory',
      'Table Error Negative': 'Please enter a positive number!',
      'Inventory Edit': 'Material Edit',
      'Inventory Quantity Add': 'Add Material Quantity'
    },
    formNCR: {
      'Table NCR Title': 'NOTE OF FINDINGS AND REPAIRS',
      'Table Details Client': 'Client Details',
      'Table Location': 'Location Details',
      'Table Benefit': 'Beneficiary',
      'Table Type': 'Subscription Type',
      'Table Order': 'Order Details',
      'Table Data': 'Start Date',
      'Table Arrive': '',
      'Table Name': 'Name',
      'Table Region': 'Region',
      'Table City': 'City',
      'Table Address': 'Address',
      'Table Contact': 'Contacts',
      'Table Add Operation': 'Add Operation',
      'Add Material': 'Add Material',
      'Add Additional Material': 'Add Additional Materials',
      'Time Data': 'Date',
      'Time Started': 'Arrival Hour',
      'Time Ended': 'Departure Hour',
      'Table Sign': 'Sign',
      'Table Representative': 'Representative',
      'Table Representative Number': 'Phone Number',
      'Table Number': 'Number',
      'Table Or': 'Or',
      'Table Delete Rep': 'Delete',
      'Table Sign Done': 'Signed',
      'Table Refrigeration': 'Technician',
      'Table Quit': 'Quit',
      'Table Save': 'Save NCR',
      'Table Operation': 'Operation Table',
      'Table SubTitle Material': 'Material Table',
      'Table Title Material': 'Additional Material Table',
      'Table Code': 'Code',
      'Table Value': 'Value',
      'Table Action': 'Actions',
      'Table NCR': 'NCR Form',
      'Table Order Title': 'Order',
      'Title Details Order': 'Order Details',
      'Title NCR': 'Note of Findings and Repairs',
      'Title Management Order': 'Order Management',
      'Table Observations': 'Observations',
    },
    employeeAccounts: {
      'Table Title': 'Employee Accounts',
      'Table Administration': 'Administration',
      'Table Settings': 'Settings',
      'Table Accounts': 'Employee Table Accounts',
      'Button Account': 'Add Account',
      'Employee Search': 'Search Accounts',
      'Table Name': 'Name',
      'Table FirstName': 'FirstName',
      'Table LastName': 'LastName',
      'Table Rol': 'Employee Role',
      'Table Work Phone': 'Work Phone Number',
      'Table Personal Phone': 'Personal Phone Number',
      'Table Last Activity': 'Last Activity',
      'Table Status': 'Account Status',
      'Table Action': 'Action',
      'Table Edit Account': 'Edit Employee Account',
      'Table Add Account': 'Add Employee Account',
      'Table Details Account': 'Details Employee Account',
      'Table Data': 'Data Account',
      'Table Data Status': 'Account Status',
      'Table Employee Data': 'Employee Data',
      'Table Phone': 'Phone Number',
      'Status Account': 'Status',
      'Btn Save': 'Save',
      'Btn Exit': 'Back',
      'Btn Edit': 'Edit Account',
      'Adding Account': 'Add Employee',
      'Table Password': 'Password',
      'Table User Data': 'The following data for the user are',
      'Table Warning User Data': 'Warning, the data will be showed only once !',
      'Table Change Prospect Btn': 'Transform to Client',
      'Table Reset Pass Btn': 'Reset Password',
      'Msg Success Reset': 'Reset password successfully!',
    },
    costumers: {
      'Individual Company': 'Company',
      'Individual Person': 'Individual',
      'Table Title': 'Clients Table',
      'Table SubTitle': 'Clients Management',
      'Table Clients': 'Clients',
      'Table Head': 'Clients Table',
      'Table Search': 'Search Clients',
      'Table ID': 'Client ID',
      'Customers Table Name': 'Name',
      'Customer Type': 'Business to business',
      'Table Type': 'Client Type',
      'Table Address': 'Address',
      'Table Contract Type': 'Contract Type',
      'Table Contact': 'Contact',
      'Table Status': 'Status',
      'Table Data Add': 'Added Date',
      'Table Add Button': 'Add Client',
      'Table Actions': 'Actions',
      'Table Management': 'Data Management',
      'Table Delete': 'Delete Client',
      'Details Title': 'Jane Rottensun',
      'Details SubTitle': 'Clients Management',
      'Details Name Clients': 'Clients',
      'Details Clients': 'Client Details',
      'Details Head': 'Client Data',
      'Details Name': 'Name',
      'Details City': 'City',
      'Details Residence': 'Residence',
      'Details Order': 'Order Number',
      'Details Location': 'Local',
      'Details Phone': 'Phone Number',
      'Details Address': 'Address',
      'Details CUI': 'CUI',
      'Details Location Button': 'Locations',
      'Details Location Add Button': 'Add Location',
      'Details Location Title': 'Location Details',
      'Details Files Title': 'Files Details',
      'Details Offer Title': 'Offer Details',
      'Details Order Title': 'Order Details',
      'Details Offer Button': 'Offers',
      'Details Files Button': 'Files',
      'Details Order Button': 'Orders',
      'Details Location Name': 'Name',
      'Details Location Loc': 'Location',
      'Details Location Phone': 'Phone Number',
      'Details Added': 'Add Location',
      'Details Add Account': 'Create Client Account',
      'Details Exit': 'Back',
      'Details Save': 'Save',
      'Detail Order Benefit': 'Benefit',
      'Detail Order Type': 'Tip Service',
      'Detail Order Address': 'Address',
      'Detail Order Creation': 'Creation Date',
      'Detail Order Final': 'Finish Date',
      'Detail Order Status': 'Status',
      'Project Delete': 'Delete',
      'Project Edit': 'Edit',
      'Delete Warning': "Remove this client if he requested that, if not, please be aware that what has been deleted can't brought back.",
      'Customer Edit Title': 'Client Edit',
      'Edit State': 'Region',
      'Edit City': 'City',
      'Table Filter': 'Filter',
      'Client Add Event': 'Add Clients',
      'Project Add Event': 'Add Client',
      'Project Quit': 'Quit',
      'Project Series': 'CI Series',
      'Project Number': 'CI Number',
      'Project LastName': 'Last Name',
      'Project FirstName': 'First Name',
      'Project Company Name': 'Company Name',
      'Project Trade Register': 'Trade Register',
      'Contact Person': 'Contact Person',
      'Customer Bank': 'Bank',
      'Customer BankAccount': 'Bank Account',
      'Customer CNP': 'CNP',
      'Customer StreetName': 'Street Name',
      'Customer StreetNumber': 'Street Number',
      'Customer Address': 'Address',
      'Customer LastName': 'Last Name',
      'Cutomer Email': 'Email',
      'Customer Main Address': 'Main Address',
      'Customer Subscriber': 'Subscription',
      'Customer NonSubscriber': 'Ocasional',
      'Customer SubscriptionType': 'SubscriptionType',
      'Customer NRC': 'NRC',
      'Customer Location Details': 'Customer Location Details',
      'Customer Contacts': 'Contacts',
      'Customer Equipments': 'Equipments',
      'Details Contacts Title': 'Contacts Details',
      'Details Contact Add Button': 'Add Contact',
      'Equipment Warranty': 'Warranty',
      'Add Equipment': 'Add Equipment',
      'Edit Location': 'Edit Location',
      'Customer Locations': 'Locations',
      'Customer Offers': 'Offers',
      'Add Contact': 'Add Contact',
      'Edit Contact': 'Edit Contact',
      'Equipments Details': 'Equipments Details',
      'Search By Name': 'Search By Name',
      'Filters Button': 'Filter',
      'No Data': 'No datas yet, add a entry',
      'Is Lead': 'Lead',
      'Is Subscribt': 'Subscription',
      'Project Company': 'Company',
      'Account Created': 'Account created!',
      'Table Prospects': 'Prospects Table',
      'Name Prospects': 'Prospects',
      'Prospect Added To Clients': 'Prospect changed!',
      'Table Password': 'Password',
      'Table User Data': 'The following data for the user are',
      'Table Warning User Data': 'Warning, the data will be showed only once !',
      'Table Change Prospect Btn': 'Transform to Client',
      'Table Reset Pass Btn': 'Reset Password',
      'Msg Success Reset': 'Reset password successfully!',
      'Table Type Service': 'Service Type',
      'Table Priority': 'Priority',
      'Table Creation Date': 'Creation Date',
      'Table Finish Date': 'Finish Date',
      'Table Limit': 'Time Limit',
      'Field Company': 'Company',
      'Field Location': 'Location',
      'Project Client': 'Client',
      'Table TechnicianResp': 'Responsible Technician',
      'Page Title': 'Clients',
      'Status Open': 'Open',
      'Status Approved': 'Approved',
      'Status Allocated': 'Alocated',
      'Status Progress': 'In progress',
      'Status Finished': 'Finished',
      'Status Delayed': 'Delayed',
      'Status Closed': 'Closed',
      'Type Normal': 'Normal',
      'Type Rev': 'Revision',
      'Tehnician unallocated': 'Unallocated',
      'Over Time': 'Over time',
      'Bill Paid': 'Paid',
      'Bill Unpaid': 'Unpaid',
    },
    //aici lucrez
    filterModal: {
      'Table Settings': 'Filter Settings',
      'Table Company': 'Company',
      'Table Customer': 'Client',
      'Table Client Type': 'Client Type',
      'Table Subscription': 'Subscription Type',
      'Table Location Residence': 'Residence',
      'Table Location City': 'City',
      'Table Technicians': 'Technician',
      'Table Service': 'Service',
      'Table Status Project': 'Project Status',
      'Table Location': 'Location',
      'Table Priority': 'Priority',
      'Table Paid': 'Bill Type',
    },
    prospects: {
      'Prospects Table': 'Prospects Table',
    },
    locations: {
      'Locations Table': 'Locations Table',
      'Routing Dashboard': 'Dashboard',
      'Routing Locations': 'Locations',
    },
    sideBar: {
      'Table Support': 'Technical Support',
      'Table Help': 'Need Help ?',
      'Table Vers': 'Version',
      'Table Quit': 'Quit',
      'Table Send': 'Send',
      'Table Observation': 'Observation',
      'Table Msg Help': 'Encountered a problem ? Please leave us a message and we will get back to you as soon as possible. ',
      'Msg Success Support': 'Message delivered!',
      'Table Error Observation': 'Please enter a message!',
      'Table Subscription Support': 'Maintenance subscription',
      'Table Active': 'Active',
      'Table Disabled': 'Disabled',
    },
    orders: {
      'Project Paid': 'Paid',
      'Home Route': 'Home',
      'Page Title': 'Axiotas Orders',
      'Table Title': 'Table Orders',
      'Table Order': 'Orders',
      'Order Search': 'Search orders ...',
      'Details Title': 'Details Orders',
      'Details Management': 'Order Management',
      'Details Order': 'Details Orders',
      'Project Add': 'Add Order',
      'Project Back': 'Back',
      'Project Approve&Alloc': 'Approve & Alloc',
      'Project Edit': 'Edit Order',
      'Table Number': 'Order Number',
      'Table Benefit': 'Benefit',
      'Table Status Order': 'Status Order',
      'Table TechnicianResp': 'Main Technician',
      'Table Client Type': 'Client Type',
      'Table Address': 'Address',
      'Table Type': 'Type',
      'Table Priority': 'Priority',
      'Table Creation Date': 'Creation Date',
      'Table Finish Date': 'Finish Date',
      'Table Status': 'Status',
      'Table Actions': 'Actions',
      'Table Limit': 'Limit',
      'Field Company': 'Company',
      'Field Location': 'Location',
      'Project Client': 'Client',
      'Field Equipments': 'Equipments',
      'Table Name': 'Name',
      'Field Services': 'Serviciu',
      'Status Open': 'Opened',
      'Tehnician unallocated': 'Unallocated',
      'Bill Paid': 'Paid',
      'Bill Unpaid': 'Unpaid',
      'Table Subscription': 'Subscription Type',
      'Type Subcriber': 'Subscribed',
      'Type Nsubcriber': 'Not Subscribed',
      'Type Normal': 'Normal',
      'Type Rev': 'Revision',
      'Field Car': 'Car',
      'Details Contacts': 'Contacts',
      'Details Paid': 'Bill Status',
      'No Note': 'No Description',
      'Over Time': 'Over time limit',
      'Date Hours': 'Hours',
      'Msg Success Add': 'Command added succesfully',
      'Button Abort': 'Abort',
      'Status Allocated': 'Alocated',
      'Status Closed': 'Closed',
      'Status Delayed': 'Delayed',
      'Table Started': 'Started Date',
      'Table Finish': 'Finished Date',
      'Table Hour Data': 'Hour',
      'Search By Name': 'Search Orders',
      'Project Start Work': 'Start Order',
      'Status Progress': 'In progress',
      'Filters Button': 'Order Filter',
      'History Orders': 'History Orders',
      'Table Management Orders': 'Management Orders',
      'Table History Orders': 'Table History Orders',
      'Table Reports': 'Reports Table',
      'Table Search Reports': 'Search Reports',
      'Filter Reports': 'Filter Reports',
      'Title Dashboard Reports': 'Reports',
      'Table Download': 'Download Reports',
      'Field Driver': 'Main Technician',
      'Order Realloc': 'Reassign',
    },
    dashboard: {
      'Dashboard Title': 'Home',
      'Dashboard Message': 'Welcome, ',
      'Dashboard ': 'Home',
      'Title Activity': 'Activity',
      'Title Total Orders': 'Total Orders',
      'Title Closed Orders': 'Closed Orders',
      'Title Current Year': 'Based on current year',
      'Title No Orders': 'You have no current orders!',
      'Title Orders': 'Orders',
      'Circle Allocated': 'Allocated',
      'Circle Approved': 'Approved',
      'Circle Delayed': 'Delayed',
      'Circle Finished': 'Finished',
      'Circle OnGoing': 'Working on order',
      'Circle Open': 'Opened',
      'Circle Closed': 'Closed',
      'Graph January': 'January',
      'Graph February': 'February',
      'Graph March': 'March',
      'Graph April': 'April',
      'Graph May': 'May',
      'Graph June': 'June',
      'Graph July': 'July',
      'Graph September': 'September',
      'Graph October': 'October',
      'Graph November': 'November',
      'Graph December': 'December',
      'Activity Order Created New': '[NEW!   Order was created]',
      'Activity Order Created': '[ Order was created ]',
      'Activity Order Approved New': '[NEW! Order was approved]',
      'Activity Order Approved': '[Order was approved]',
      'Activity Order Finished New': '[NEW! Order was finished]',
      'Activity Order Finished': '[Order was finished]',
      'Activity Order Delayed New': '[NEW! Order was delayed]',
      'Activity Order Delayed': '[Order was delayed]',
      'Activity Order Alocated New': '[NEW!  Order was alocated]',
      'Activity Order Alocated': '[Order was alocated]',
      'Activity Order Progress New': '[NEW!  Order is on working]',
      'Activity Order Progress': '[Order is on working]',
      'Activity Order Closed New': '[NEW! Order was closed]',
      'Activity Order Closed': '[Order was closed]',
    },
    generalSettings: {
      'Role List': 'Role list',
      'Add role': 'Add role',
      'Role name': 'Role Name',
      'General settings': 'General settings',
      'Role deletion message': 'Role deletion message',
      'Role deletion': 'Role deletion'
    },
    generalTranslation: {
      'Save': 'Save',
      'Cancel': 'Cancel',
      'Name': 'Name',
      'Done': 'Done',
      'Back': 'Back',
      'Delete': 'Delete'
    },
    permissions: {
      'ADMIN': 'Admin',
      'DISPATCHER': 'Dispatcher',
      'TECHNICIAN': 'Technician',
      'CLIENT_ACCOUNT': 'Client account',
      'COMPANY': 'Company',
      'OPERATIONS': 'Operations',
      'SERVICES': 'Services',
      'CARS': 'Cars',
      'INVENTORY': 'Inventory',
      'ORDERS': 'Orders',
      'APP_SETTINGS': 'Application settings',
      'REPORTS': 'Reports',
      'CREATE': 'Create',
      'READ': 'Read',
      'UPDATE': 'Update',
      'DELETE': 'Delete',
      'APPROVE': 'Approve',
      'ALLOCATION': 'Allocation',
      'TAKE': 'Take',
      'EXECUTE': 'Execute',
      'APPROVE_CLOSING': 'Approve closing',
      'EMPLOYEE_ACCOUNTS': 'Employee accounts',
      'CLIENTS_ACCOUNT': 'Client accounts',
      'GENERAL_SETTINGS': 'General settings',
      'CLIENTS': 'Clients',
      'CUSTOMERS': 'Clients',
    },
    notificationTranslation: {
      'Update sucessfully': 'Update successfully!',
      'Error': 'Error, update not successfully!',
    },
    modalCSV: {
      'Add Material': 'Import Materials',
      'Table Load': 'Load',
      'Table Quit': 'Abort',
      'File Ready': 'File is ready',
      'Missing Codes': 'File is missing products codes',
      'Missing Class': 'File is missing class column',
      'Empty File': 'The file is empty',
      'Load File': 'Load a file!'
    },
    version: {
      'Version Label': 'Version'
    }
  },
  ro: {
    translation: {
      'Example ': 'Traducere',
    },
    modalSignature: {
      'Table Sign': 'Semnătură',
      'Table Save': 'Salvează',
      'Table Quit': 'Renunță',
      'Table Quantity': 'Cantitate',
      'Table Product Name': 'Denumire Produs',
      'Table UM': 'Unitate de Măsură',
      'Table Add Material': 'Adaugă material adițional',
      'Table Error High Number': 'Vă rog introduceți o valoare mai mare decât 0!',
      'Table Error UM': 'Vă rog introduceți o unitate de măsură!',
      'Table Error Name': 'Vă rog introduceți un nume!',
      'Table Operation': 'Vă rog alegeți o operație!',
      'Table Add Operation': 'Adaugă Operație',
      'Table Problem': 'Descrie Problema',
      'Quantity Car': 'Cantitate în Vehicul',
      'Add Material': 'Adaugă Material',
      'Quantity Car Error': 'Cantitate insuficientă în vehicul!',
    },
    login: {
      'Table Login': 'Autentificare',
      'Table Title': 'Conectați-vă pe platforma internă',
      'Table Email': 'Adresa de E-mail',
      'Table Password': 'Parola',
      'Table Forgot Password': 'Parolă Uitată',
      'Table Connect': 'Conectare',
      'Table Error Email': 'Vă rog introduceți Email-ul!',
      'Table Error Email Valid': 'Email-ul nu este valid!',
      'Table Error Password Valid': 'Vă rog introduceți parola!',
      'Table Error Login Pass': 'Email sau parolă incorectă!',
      'Table Error Login Email': 'Email sau parolă incorectă!',
      'Server Error': 'Nu s-a putut face conexiunea cu serverul!',
      'Went Wrong': 'Ceva a mers rău, încearcă din nou în cateva minute.',
    },
    services: {
      'Table Type': 'Nume Serviciu',
      'Table Company': 'Firmă',
      'Table Actions': 'Acțiuni',
      'Table Button': 'Adăugare Serviciu',
      'Table Tab': 'Tabel Servicii',
      'Table Search': 'Căutare Servicii',
      'Table Title': 'Servicii',
      'Table Details': 'Detalii',
      'Table Dashboard': 'Administrare',
      'Table Nomenclatures': 'Nomenclatoare',
      'Table Services': 'Services',
      'Table Edit': 'Editare Serviciu',
      'Table Name': 'Nume',
      'Table Add': 'Adăugare Serviciu',
      'Table Exit': 'Renunță',
      'Table Save': 'Salvează',
      'Table Link': 'Adăugare Serviciu',
      'Table Management': 'Management',
      'Table Title Details': 'Detalii Servicii',
      'Delete Warning': 'Eliminați acest serviciu dacă a-ți solicitat acest lucru, dacă nu, vă rugăm să rețineți că ceea ce a fost șters nu se poate aduce înapoi.',
      'Delete Button': 'Ștergere',
      'Success Message': 'Serviciul a fost adăugat!',
      'Success Edit': 'Serviciul a fost actualizat!',
      'Table Error Name': 'Vă rog introduceți numele serviciului!',
      'Table Error Company': 'Vă rog selectați firma!',
      'Default operation': 'Operație implicită'
    },
    clientsAccounts: {
      'Table Title': 'Conturi Clienți',
      'Table Administration': 'Administrare',
      'Table Settings': 'Setări Aplicație',
      'Table Accounts List': 'Tabel Conturi Clienți',
      'Table List': 'Tabel Conturi',
      'Table Search': 'Căutare Conturi',
      'Table Name': 'Nume',
      'Table Type': 'Tip Client',
      'Table Phone': 'Număr Telefon',
      'Table Last Activity': 'Ultima Activitate',
      'Table Status': 'Stare Cont',
      'Table Action': 'Acțiuni',
      'Details Clients Account': 'Detalii Cont Client',
      'Client Account Data': 'Date Cont Client',
      'Account Data': 'Date Cont',
      'Btn Back': 'Înapoi',
      'Btn Block': 'Blocare Cont',
      'Btn UnBlock': 'Deblocare Cont',
      'Table FirstName': 'Nume',
      'Table LastName': 'Prenume',
      'Table Quit': 'Renunță',
      'Msg Block': 'Doriți blocarea acestui cont ?',
      'Msg UnBlock': 'Doriți deblocarea acestui cont ?',
      'Msg Success': 'Starea contului a fost actualizată!',
      'Table Password': 'Parola',
      'Table User Data': 'Datele utilizatorului sunt următoarele:',
      'Table Warning User Data': 'Atenție ! Datele vor fi afișate o singură dată.',
      'Table Change Prospect Btn': 'Transformare în Client',
      'Table Reset Pass Btn': 'Resetare Parolă',
      'Msg Success Reset': 'Parola a fost resetată!',
    },
    operations: {
      'Table Type': 'Nume',
      'Table Money': 'LEI + TVA',
      'Table Actions': 'Acțiuni',
      'Table Button': 'Adăugare Operațiune',
      'Table Tab': 'Tabel Operațiuni',
      'Table Search': 'Căutare operațiuni',
      'Table Title': 'Operațiuni',
      'Table UM': 'Unitate de Măsură',
      'Table Dashboard': 'Administrare',
      'Table Details': 'Detalii',
      'Table Code': 'Cod',
      'Table Price': 'Preț',
      'Table Service': 'Tip Serviciu',
      'Table Nomenclatures': 'Nomenclatoare',
      'Table Services': 'Operațiuni',
      'Table Edit': 'Editare Operațiune',
      'Table Name': 'Nume',
      'Table Exit': 'Renunță',
      'Table Save': 'Salvează',
      'Table Link': 'Adăugare Operațiune',
      'Table Management': 'Management',
      'Table Title Details': 'Detalii Operațiune',
      'Delete Warning': 'Eliminați acestă operațiune dacă a-ți solicitat acest lucru, dacă nu, vă rugăm să rețineți că ceea ce a fost șters nu se poate aduce înapoi.',
      'Delete Button': 'Ștergere',
      'Msg Success': 'Operațiunea a fost actualizată!',
      'Msg Success Add': 'Operațiunea a fost adăugată!',
      'Table Error Name': 'Vă rog introduceți numele operațiunii!',
      'Table Error Code': 'Vă rog introduceți codul operațiunii!',
      'Table Error UnitMeasure': 'Vă rog introduceți unitatea de măsură a operațiunii!',
      'Table Error Price': 'Vă rog introduceți prețul operațiunii',
      'Table Error Service Type': 'Vă rog selectați un serviciu!',
    },
    vehicles: {
      'Table Type': 'Nume',
      'Table Money': 'LEI + TVA',
      'Table Actions': 'Acțiuni',
      'Table Button': 'Adăugare Vehicul',
      'Table Tab': 'Tabel Vehicule',
      'Table Search': 'Căutare vehicul',
      'Table Title': 'Vehicule',
      'Table Dashboard': 'General',
      'Table Details': 'Detalii',
      'Table Nomenclatures': 'Nomenclatoare',
      'Table Services': 'Vehicule',
      'Table Company': 'Firmă',
      'Table Edit': 'Editare Vehicul',
      'Table Add': 'Adăugare Vehicul',
      'Table Name': 'Nume',
      'Table Exit': 'Renunță',
      'Table Save': 'Salvează',
      'Table Link': 'Adăugare Vehicul',
      'Table Management': 'Management',
      'Table Title Details': 'Detalii Vehicul',
      'Delete Warning': 'Eliminați acest vehicul dacă a-ți solicitat acest lucru, dacă nu, vă rugăm să rețineți că ceea ce a fost șters nu se poate aduce înapoi.',
      'Delete Button': 'Ștergere',
      'Table Vehicle': 'Vehicul',
      'Table Driver': 'Tehnician',
      'Table Status': 'Stare',
      'Table Vehicle Id': 'Număr Înmatriculare',
      'Table Insurance': 'Asigurare',
      'Table Desc': 'ID Denumire',
      'Table Vendor': 'Inventar Vehicul',
      'Id Product': 'ID Produs',
      'Product Name': 'Denumire',
      'UM ': 'UM',
      'Quantity ': 'Cantitate',
      'Table Plate': 'Număr Masină',
      'Table Owner': 'Tehnician',
      'Table ASIG': 'Asigurare',
      'Table Error Name': 'Vă rog introduceți numele vehiculului!',
      'Table Error Plate': 'Vă rog introduceți numarul vehiculului!',
      'Table Error Owner': 'Vă rog introduceți numele tehnicianul!',
      'Table Error Company': 'Vă rog selectați firma!',
      'Table Error ROV': 'Vă rog introduceți data rovinietei vehiculului !',
      'Table Error ITP': 'Vă rog introduceți data inspectiei tehnice vehiculului!',
      'Table Error ASIG': 'Vă rog introduceți data asigurarea vehiculului!',
      'Table Error Status': 'Vă rog introduceți starea vehiculului!',
      'Table Success Add': 'Vehiculul a fost creat!',
      'Table Success Edit': 'Vehiculul a fost actualizat!',
      'Table Code': 'Code',
      'Table Material Class': 'Clasă Produs',
      'Table Quantity': 'Cantitate',
      'Table UM': 'UM',
      'Table Product': 'Listă Materiale',
      'Table Error Vehicle Quantity': 'Vă rog introduceți cantitatea produsului!',
      'Table Error Vehicle Company': 'Vă rog selectați produsul!',
      'Table Material Success Add': 'Materialul a fost adăugat!',
      'Table Date': 'Data',
      'Table Hour': 'Ora',
      'Table Name Product': 'Denumirea Produsului',
      'Table Action': 'Acțiuni',
      'Table Author': 'Autor',
    },
    companies: {
      'Table Type': 'Nume',
      'Table Money': 'LEI + TVA',
      'Table Actions': 'Acțiuni',
      'Table Button': 'Adăugare Firmă',
      'Table Add': 'Adăugare Firmă',
      'Table Tab': 'Tabel Firme',
      'Table Search': 'Căutare firme',
      'Table Title': 'Firme',
      'Table Dashboard': 'Administrare',
      'Table Details': 'Detalii',
      'Table Nomenclatures': 'Nomenclatoare',
      'Table Services': 'Firme',
      'Table Edit': 'Editare Firmă',
      'Table Name': 'Nume',
      'Table Exit': 'Renunță',
      'Table Save': 'Salvează',
      'Table Link': 'Adăugare Firmă',
      'Table Management': 'Management',
      'Table Title Details': 'Detalii Firme',
      'Table Status': 'Stare',
      'Delete Warning': 'Eliminați acestă firmă dacă a-ți solicitat acest lucru, dacă nu, vă rugăm să rețineți că ceea ce a fost șters nu se poate aduce înapoi.',
      'Delete Button': 'Ștergere',
      'Msg Success': 'Firma a fost actualizată!',
      'Msg Success Add': 'Firma a fost creată!',
      'Table Error Name': 'Vă rog introduceți numele firmei!',
      'Table Error Cui': 'Vă rog introduceți codul firmei!',
      'Table Error Status': 'Vă rog selectați o stare a firmei!',
      'Table Data': 'Data',
      'Table Hour': 'Ora',
      'Table Product Name': 'Denumire Material',
      'Table Author': 'Autor',
      'Table Actions History': 'Acțiuni',
      'Table Quantity': 'Cantitate',
    },
    profile: {
      'Profile ': 'Profil',
      'Tab General': 'General',
      'Tab Notification': 'Notificări',
      'Tab Security': 'Securitate',
      'Dashboard ': 'Acasă',
      'Account ': 'Cont',
      'Table Name': 'Nume',
      'Table Email': 'Email',
      'Table Last Name': 'Prenume',
      'Table Phone': 'Număr Telefon',
      'Table Address': 'Adresă',
      'Table Country': 'Țara',
      'Table City': 'Oraș',
      'Table Postal Code': 'Codul Poștal',
      'Table Role': 'Rol:',
      'Contact Info': 'Informații Contact',
      'Picture ': 'Înlocuire poză profil',
      'Table Theme': 'Tema Aplicației',
      'Table Logout': 'Deconectare',
      'Table Settings Password': 'Setări Parolă',
      'Table Change': 'Schimbă Parola',
      'Table Password': 'Parola Curentă',
      'Table New Password': 'Parola Nouă',
      'Table Confirm Password': 'Confirmare Parola Nouă',
      'Password Success': 'Parola a fost actualizată!',
      'Password Match': 'Parola nu corespunde!',
      'Password Req Current': 'Vă rog introduceți parola curentă!',
      'Password Req New': 'Vă rog introduceți parola nouă!',
      'Password Length': 'Parola trebuie să conțină minim 7 caractere!',
      'Msg Success': 'Parola a fost actualizată!',
    },
    inventory: {
      'PricePQ ': 'Preț / UM',
      'Table Type': 'ID Material',
      'Table Company': 'Clasă Material',
      'Table Actions': 'Acțiuni',
      'Table Button': 'Adăugare Material',
      'Table Tab': 'Tabel Servicii',
      'Table Search': 'Căutare Material',
      'Table Title': 'Inventar',
      'Table Details': 'Detalii',
      'Table Dashboard': 'General',
      'Table Nomenclatures': 'Administrator',
      'Table Services': 'Inventar',
      'Table Edit': 'Editare Material',
      'Table Name': 'Nume',
      'Table Add': 'Adăugare Material',
      'Table Exit': 'Renunță',
      'Table Save': 'Salvează',
      'Table Link': 'Adăugare Material',
      'Table Management': 'Management',
      'Table Title Details': 'Detalii Material',
      'Delete Warning': 'Eliminați acest Inventar dacă a-ți solicitat acest lucru, dacă nu, vă rugăm să rețineți că ceea ce a fost șters nu se poate aduce înapoi.',
      'Delete Button': 'Ștergere',
      'Table Design': 'Denumire',
      'Table Export': 'Descarcă Inventar',
      'Quantity ': 'Cantitate',
      'UM ': 'UM',
      'Table Class': 'Clasă Produs',
      'Table Status': 'Stare',
      'Table Company Inventory': 'Firmă',
      'Table Company Vehicle': 'Inventar',
      'Table Code': 'Cod',
      'Table Material Class': 'Categorie Material',
      'Table UM': 'UM',
      'Table Quantity': 'Cantitate',
      'Table General Title': 'General',
      'Table Add Product': 'Adăugare Produs',
      'Table Add Material Inventory': 'Adăugare cantitate material în inventar',
      'Table Quantity Material Inventory': 'Cantitate Material',
      'Table Edit Inv': 'Editare Material',
      'Table Material Info': 'Informații Material',
      'Table Error Name': 'Vă rog introduceți numele produsului!',
      'Table Error Code': 'Vă rog introduceți codul produsului!',
      'Table Error Material': 'Vă rog introduceți clasa produsului!',
      'Table Error Quantity': 'Vă rog introduceți cantitatea produsului!',
      'Table Error UM': 'Vă rog introduceți unitatea de masura a produsului!',
      'Table Error Negative': 'Vă rog introduceți un număr positiv',
      'Table Success Add': 'Produsul a fost adăugat!',
      'Table Success Edit': 'Produsul a fost actualizat!',
      'Table Import': 'Importă CSV',
      'General Inventory': 'Inventar General',
      'Inventory Edit': 'Editare Material',
      'Inventory Quantity Add': 'Adăugare Cantitate Material'
    },
    costumers: {
      'Individual Company': 'Persoană Juridică',
      'Individual Person': 'Persoană Fizică',
      'Table Title': 'Tabel Clienți',
      'Table SubTitle': 'Management Clienți',
      'Table Clients': 'Clienți',
      'Table Head': 'Tabel Clienți',
      'Table Search': 'Căutare Clienți',
      'Table ID': 'ID Client',
      'Customers Table Name': 'Nume',
      'Table Type': 'Tip Client',
      'Customer Type': 'Persoana juridica',
      'Table Address': 'Adresă',
      'Table Contract Type': 'Tip Contract',
      'Table Contact': 'Contact',
      'Table Status': 'Stare',
      'Table Data Add': 'Data Adăugării',
      'Table Actions': 'Acțiuni',
      'Table Add Button': 'Adăugare Client',
      'Table Management': 'Management Date',
      'Table Delete': 'Ștergere Client',
      'Details Title': 'Jane Rottensun',
      'Details SubTitle': 'Management Clienți',
      'Details Name Clients': 'Clienți',
      'Details Clients': 'Detalii Clienți',
      'Details Head': 'Date Client',
      'Details Name': 'Nume',
      'Details City': 'Oraș',
      'Details Order': 'Număr Comandă',
      'Details Location': 'Localitate',
      'Details Phone': 'Număr Telefon',
      'Details Address': 'Adresă',
      'Details CUI': 'CUI',
      'Details Location Button': 'Locații',
      'Details Location Title': 'Detalii Locație',
      'Details Files Title': 'Detalii Fișiere',
      'Details Offer Title': 'Detalii Oferte',
      'Details Order Title': 'Detalii Comenzi',
      'Details Location Add Button': 'Adăugare Locație',
      'Details Offer Button': 'Oferte',
      'Details Files Button': 'Fișiere',
      'Details Order Button': 'Comenzi',
      'Details Added': 'Adăugare Locație',
      'Details Location Name': 'Nume',
      'Details Location Loc': 'Locație',
      'Details Location Phone': 'Număr De Telefon',
      'Details Exit': 'Renunță',
      'Details Save': 'Salvează',
      'Detail Order Benefit': 'Beneficiar',
      'Detail Order Type': 'Tip Serviciu',
      'Detail Order Address': 'Adresă',
      'Detail Order Creation': 'Data Creării',
      'Detail Order Final': 'Data Finalizării',
      'Detail Order Status': 'Stare',
      'Project Delete': 'Ștergere',
      'Project Edit': 'Editare',
      'Details Add Account': 'Creare Cont Client',
      'Delete Warning': 'Eliminați acest client dacă a-ți solicitat acest lucru, dacă nu, vă rugăm să rețineți că ceea ce a fost șters nu se poate aduce înapoi.',
      'Customer Edit Title': 'Editare Client',
      'Edit State': 'Regiune',
      'Edit City': 'Oraș',
      'Details Residence': 'Județ',
      'Table Filter': 'Filtru',
      'Client Add Event': 'Adăugare Clienți',
      'Project Add Event': 'Adaugă Client',
      'Project Quit': 'Renunță',
      'Project Series': 'Serie CI',
      'Project Number': 'Număr CI',
      'Project LastName': 'Nume',
      'Project FirstName': 'Prenume',
      'Project Company Name': 'Nume Firmă',
      'Project Trade Register': 'Registrul Comerțului',
      'Contact Person': 'Persoană de Contact',
      'Customer Bank': 'Banca',
      'Customer BankAccount': 'Cont Bancar',
      'Customer CNP': 'CNP',
      'Customer StreetName': 'Nume Stradă',
      'Customer StreetNumber': 'Număr Stradă',
      'Customer Address': 'Bloc, nr. etaj, ap.',
      'Customer LastName': 'Prenume',
      'Cutomer Email': 'Email',
      'Customer Main Address': 'Adresa Sediu',
      'Customer Subscriber': 'Abonat',
      'Customer NonSubscriber': 'Ocazional',
      'Customer SubscriptionType': 'Tip Abonament',
      'Customer NRC': 'NRC',
      'Customer Location Details': 'Detalii Locatie',
      'Customer Contacts': 'Contacte',
      'Customer Equipments': 'Echipamente',
      'Details Contacts Title': 'Detalii Contacte',
      'Details Contact Add Button': 'Adaugă Contact',
      'Equipment Warranty': 'Garanție',
      'Add Equipment': 'Adaugă Echipament',
      'Edit Location': 'Editare Locație',
      'Customer Locations': 'Locații',
      'Customer Offers': 'Oferte',
      'Add Contact': 'Adaugă Contact',
      'Edit Contact': 'Edit Contact',
      'Equipments Details': 'Detalii Echipamente',
      'Search By Name': 'Caută după nume',
      'Filters Button': 'Filtrează',
      'No Data': 'Nu există date adaugate',
      'Is Lead': 'Prospect',
      'Is Subscribt': 'Abonat',
      'Project Company': 'Companie',
      'Account Created': 'Contul a fost creat!',
      'Table Prospects': 'Tabel Prospecți',
      'Name Prospects': 'Prospecți',
      'Prospect Added To Clients': 'Prospect schimbat cu succes!',
      'Table Password': 'Parola',
      'Table User Data': 'Datele utilizatorului sunt următoarele:',
      'Table Warning User Data': 'Atenție ! Datele vor fi afișate o singură dată.',
      'Table Change Prospect Btn': 'Transformare în Client',
      'Table Type Service': 'Tip Serviciu',
      'Table Priority': 'Prioritate',
      'Table Creation Date': 'Data Începere',
      'Table Finish Date': 'Data Finalizării',
      'Table Limit': 'Timp Limită',
      'Field Company': 'Firmă',
      'Field Location': 'Locație',
      'Project Client': 'Client',
      'Table TechnicianResp': 'Tehnician Responsabil',
      'Page Title': 'Clienți',
      'Status Open': 'Deschisă',
      'Status Approved': 'Aprobată',
      'Status Allocated': 'Alocată',
      'Status Progress': 'În lucru',
      'Status Finished': 'Finalizată',
      'Status Delayed': 'Amânată',
      'Status Closed': 'Închisă',
      'Type Normal': 'Normală',
      'Type Rev': 'Revizie',
      'Tehnician unallocated': 'Nealocat',
      'Over Time': 'Peste termenul limită',
      'Bill Paid': 'Achitat',
      'Bill Unpaid': 'Neachitat',
    },
    //aici lucrez
    formNCR: {
      'Table NCR Title': 'NOTĂ DE CONSTATARE ȘI REPARAȚII',
      'Table Details Client': 'Detalii Client',
      'Table Location': 'Detalii Locație',
      'Table Benefit': 'Beneficiar',
      'Table Type': 'Tip Abonament',
      'Table Order': 'Detalii Comanda',
      'Table Data': 'Data Începerii',
      'Table Arrive': '',
      'Table Name': 'Nume',
      'Table Region': 'Județ',
      'Table City': 'Oraș',
      'Table Address': 'Adresă',
      'Table Contact': 'Contacte',
      'Table Add Operation': 'Adaugă Operație',
      'Add Material': 'Adaugă Materiale',
      'Add Additional Material': 'Adaugă Materiale Adiționale',
      'Time Data': 'Data',
      'Time Started': 'Ora Sosirii',
      'Time Ended': 'Ora Plecării',
      'Table Sign': 'Semnează',
      'Table Sign Done': 'Semnat',
      'Table Representative': 'Reprezentant',
      'Table Or': 'Sau',
      'Table Representative Number': 'Număr de telefon',
      'Table Number': 'Număr',
      'Table Delete Rep': 'Șterge',
      'Table Refrigeration': 'Tehnician',
      'Table Quit': 'Renunță',
      'Table Save': 'Salvează NCR',
      'Table Operation': 'Tabel Operații',
      'Table Code': 'Cod',
      'Table Value': 'Valoare',
      'Table Action': 'Acțiuni',
      'Table SubTitle Material': 'Table Materiale',
      'Table Title Material': 'Tabel Materiale Adiționale',
      'Table NCR': 'Formular NCR',
      'Table Order Title': 'Comenzi',
      'Title Details Order': 'Detalii Comandă',
      'Title NCR': 'Notă de Constatare și Reparații',
      'Title Management Order': 'Management Comenzi',
      'Table Observations': 'Observații',
    },
    filterModal: {
      'Table Settings': 'Setări Filtru',
      'Table Company': 'Firma',
      'Table Customer': 'Client',
      'Table Client Type': 'Tip Client',
      'Table Subscription': 'Tip Abonament',
      'Table Location Residence': 'Oraș',
      'Table Location City': 'Județ',
      'Table Technicians': 'Tehnician',
      'Table Service': 'Serviciu',
      'Table Status Project': 'Stare Proiect',
      'Table Location': 'Locație',
      'Table Priority': 'Prioritate',
      'Table Paid': 'Tip Factură',
    },
    prospects: {
      'Prospects Table': 'Tabel Prospecți',
    },
    locations: {
      'Locations Table': 'Tabel Locații',
      'Routing Dashboard': 'Acasă',
      'Routing Locations': 'Locații',
    },
    employeeAccounts: {
      'Table Title': 'Conturi Angajați',
      'Table Administration': 'Administrare',
      'Table Settings': 'Setări Aplicație',
      'Table Accounts': 'Tabel Conturi Angajați',
      'Button Account': 'Adăugare Cont',
      'Employee Search': 'Căutare Conturi',
      'Table Name': 'Nume',
      'Table FirstName': 'Nume',
      'Table LastName': 'Prenume',
      'Table Rol': 'Rol',
      'Table Work Phone': 'Număr Telefon Serviciu',
      'Table Personal Phone': 'Număr Telefon Personal',
      'Table Last Activity': 'Ultima Activitate',
      'Table Status': 'Stare Cont',
      'Table Action': 'Acțiuni',
      'Table Edit Account': 'Editare Cont Angajat',
      'Table Add Account': 'Adăugare Cont Angajat',
      'Table Details Account': 'Detalii Cont Angajat',
      'Table Data': 'Date Cont',
      'Table Data Status': 'Starea Contului',
      'Table Employee Data': 'Date Angajat',
      'Table Phone': 'Număr Telefon',
      'Status Account': 'Stare',
      'Btn Save': 'Salvează',
      'Btn Exit': 'Renunță',
      'Btn Edit': 'Editează Contul',
      'Adding Account': 'Adăugare Angajat',
      'Success Add': 'Contul a fost creat!',
      'Success Edit': 'Contul a fost actualizat!',
      'Table Error FirstName': 'Vă rog introduceți numele!',
      'Table Error LastName': 'Vă rog introduceți prenumele!',
      'Table Error Work Phone Type': 'Vă rog introduceți doar cifre!',
      'Table Error Work Phone Low': 'Numărul de telefon introdus este prea scurt!',
      'Table Error Work Phone High': 'Numărul de telefon introdus este prea lung!',
      'Table Error Work Phone Req': 'Vă rog introduceți un număr de telefon!',
      'Table Error Email Valid': 'Vă rog introduceți un email valid!',
      'Table Error Email Req': 'Vă rog introduceți email-ul!',
      'Table Error Role': 'Vă rog selectați un rol!',
      'Table Error Status': 'Vă rog selectați o stare a contului!',
      'Table Password': 'Parola',
      'Table User Data': 'Datele utilizatorului sunt următoarele:',
      'Table Warning User Data': 'Atenție ! Datele vor fi afișate o singură dată.',
      'Table Change Prospect Btn': 'Transformare în Client',
      'Table Reset Pass Btn': 'Resetare Parolă',
      'Msg Success Reset': 'Parola a fost resetată!',
    },
    sideBar: {
      'Table Support': 'Suport Tehnic',
      'Table Help': 'Aveți nevoie de ajutor?',
      'Table Vers': 'Versiunea',
      'Table Quit': 'Renunță',
      'Table Send': 'Trimite',
      'Table Observation': 'Observații',
      'Table Msg Help': 'Ai întâmpinat o problemă ? Vă rugăm să ne lăsați un mesaj.',
      'Msg Success Support': 'Mesajul a fost trimis!',
      'Table Error Observation': 'Vă rog introduceți un mesaj!',
      'Table Subscription Support': 'Abonament de mentenanță',
      'Table Active': 'Activ',
      'Table Disabled': 'Dezactivat',
    },
    orders: {
      'Project Paid': 'Achitat',
      'Home Route': 'Acasă',
      'Page Title': 'Axiotas Comenzi',
      'Table Title': 'Tabel Comenzi',
      'Table Order': 'Comenzi',
      'Order Search': 'Căutare  Comenzi ...',
      'Details Title': 'Detalii Comenzi',
      'Details Management': 'Management Comenzi',
      'Details Order': 'Detalii Comandă',
      'Project Add': 'Adăugare Comandă',
      'Project Back': 'Înapoi',
      'Project Approve&Alloc': 'Aprobă & Alocă',
      'Project Edit': 'Editează Comanda',
      'Table Number': 'ID Comanda',
      'Table Benefit': 'Beneficiar',
      'Table Status Order': 'Stare Comandă',
      'Table TechnicianResp': 'Technician Responsabil',
      'Table Client Type': 'Tip Client',
      'Table Address': 'Adresă',
      'Table Type': 'Tip',
      'Table Priority': 'Prioritate',
      'Table Creation Date': 'Data Creării',
      'Table Finish Date': 'Data Finalizării',
      'Table Status': 'Stare',
      'Table Actions': 'Acțiuni',
      'Table Limit': 'Timp limită',
      'Field Company': 'Companie',
      'Field Location': 'Locație',
      'Project Client': 'Client',
      'Field Equipments': 'Echipamente',
      'Table Name': 'Nume',
      'Field Services': 'Serviciu',
      'Status Open': 'Deschisă',
      'Tehnician unallocated': 'Nealocat',
      'Table Subscription': 'Tip Abonament',
      'Type Subcriber': 'Abonament',
      'Type Nsubcriber': 'Ocazional',
      'Type Normal': 'Normală',
      'Type Rev': 'Revizie',
      'Field Car': 'Mașină',
      'Details Paid': 'Status Plată',
      'Details Contacts': 'Contacte',
      'No Note': 'Nu exista descriere',
      'Over Time': 'Peste timpul limită',
      'Date Hours': 'Ore',
      'Msg Success Add': 'Comanda a fost adăugată',
      'Button Abort': 'Renunță',
      'Status Allocated': 'Alocată',
      'Status Delayed': 'Amânată',
      'Status Closed': 'Închisă',
      'Table Started': 'Dată Început',
      'Table Finish': 'Dată Finalizare',
      'Table Hour Data': 'Oră',
      'Search By Name': 'Căutare Comenzi',
      'Project Start Work': 'Începe Comanda',
      'Status Progress': 'În lucru',
      'Filters Button': 'Filtru Comenzi',
      'Bill Paid': 'Achitat',
      'Bill Unpaid': 'Neachitat',
      'History Orders': 'Istoric Comenzi',
      'Table Management Orders': 'Managment Comenzi',
      'Table History Orders': 'Tabel Istoric Comenzi',
      'Status Finished': 'Finalizată',
      'Oder NCRs': "NCR-urile",
      'Name': "Nume",
      'Create Date': "Dată creare",
      'Project Delay': "Amânare",
      'Project Finish': "Finalizare",
      'Project SaveNCR': "Salvare",
      'Project Alloc': 'Alocare',
      'Table Search Reports': 'Căutare Rapoarte',
      'Filter Reports': 'Filtru Rapoarte',
      'Title Dashboard Reports': 'Rapoarte',
      'Table Download': 'Descarcă Rapoarte',
      'Field Driver': 'Tehnician responsabil / Sofer',
      'Order Realloc': 'Realocă',
    },
    dashboard: {
      'Dashboard Title': 'Acasă',
      'Dashboard Message': 'Bun venit, ',
      'Dashboard ': 'Acasă',
      'Title Activity': 'Activitate',
      'Title Total Orders': 'Total Comenzi',
      'Title Closed Orders': 'Comenzi Închise',
      'Title Current Year': 'Perioada anului curent',
      'Title No Orders': 'Nu aveți comenzi!',
      'Title Orders': 'Comenzi',
      'Circle Allocated': 'Alocate',
      'Circle Approved': 'Aprobate',
      'Circle Delayed': 'Amânate',
      'Circle Finished': 'Finalizate',
      'Circle OnGoing': 'În desfașurare',
      'Circle Open': 'Deschise',
      'Circle Closed': 'Închise',
      'Graph January': 'Ianuarie',
      'Graph February': 'Februarie',
      'Graph March': 'Martie',
      'Graph April': 'Aprilie',
      'Graph May': 'Mai',
      'Graph June': 'Iunie',
      'Graph July': 'Iulie',
      'Graph September': 'Septembrie',
      'Graph October': 'Octombrie',
      'Graph November': 'Noiembrie',
      'Graph December': 'Decembrie',
      'Activity Order Created New': '[NOU!   Comanda a fost creată]',
      'Activity Order Created': '[Comanda a fost creată]',
      'Activity Order Approved New': '[NOU! Comanda a fost aprobată]',
      'Activity Order Approved': '[Comanda a fost aprobată]',
      'Activity Order Finished New': '[NOU! Comanda a fost finalizată]',
      'Activity Order Finished': '[Comanda a fost finalizată]',
      'Activity Order Delayed New': '[NOU! Comanda a fost amânată]',
      'Activity Order Delayed': '[Comanda a fost amânată]',
      'Activity Order Alocated New': '[NOU!   Comanda a fost alocată]',
      'Activity Order Alocated': '[Comanda a fost alocată]',
      'Activity Order Progress New': '[NOU!   Comanda este în desfăsurare]',
      'Activity Order Progress': '[Comanda este în desfăsurare]',
      'Activity Order Closed New': '[NOU! Comanda a fost închisă]',
      'Activity Order Closed': '[Comanda a fost închisă]',
    },
    generalSettings: {
      'Role List': 'Listă de roluri',
      'Add role': 'Adăugare rol nou',
      'Role name': 'Denumirea rolului',
      'General settings': 'Setări Generale',
      'Role deletion message': 'Rolul va fi șters',
      'Role deletion': 'Ștergere rolul'
    },
    generalTranslation: {
      'Save': 'Salvare',
      'Cancel': 'Anulare',
      'Name': 'Denumire',
      'Done': 'Termină',
      'Back': 'Înapoi',
      'Delete': 'Ștergere'
    },
    permissions: {
      'ADMIN': 'Admin',
      'DISPATCHER': 'Dispecer',
      'TECHNICIAN': 'Tehnician',
      'CLIENT_ACCOUNT': 'Cont Client',
      'COMPANY': 'Firme',
      'OPERATIONS': 'Operați',
      'SERVICES': 'Servicii',
      'CARS': 'Mașini',
      'INVENTORY': 'Inventar',
      'ORDERS': 'Comenzi',
      'APP_SETTINGS': 'Setării aplicație',
      'REPORTS': 'Rapoarte',
      'CREATE': 'Creare',
      'READ': 'Vizualizare',
      'UPDATE': 'Editare',
      'DELETE': 'Ștergere',
      'APPROVE': 'Aprobare',
      'ALLOCATION': 'Alocare',
      'TAKE': 'Preluare',
      'EXECUTE': 'Executare',
      'APPROVE_CLOSING': 'Aprobare inchidere',
      'EMPLOYEE_ACCOUNTS': 'Conturi angajați',
      'CLIENTS_ACCOUNT': 'Conturi clienți',
      'GENERAL_SETTINGS': 'Setări generale',
      'CLIENTS': 'Clienți',
      'CUSTOMERS': 'Clienți',
    },
    notificationTranslation: {
      'Update sucessfully': 'Actualizarea realizată cu sucess!',
      'Error': 'Eroarea, actualizare nereusită!',
    },
    general: {
      'Error': 'Actualizare nereusita!',
      'Success': 'Succes!',
      'Exit': 'Renunță',
      'Save': 'Salvează',
    },
    modalCSV: {
      'Add Material': 'Importă Materiale',
      'Table Load': 'Incarcă',
      'Table Quit': 'Renunță',
      'File Ready': 'Fișierul este pregătit',
      'Missing Codes': 'Fișierul conține coduri lipsă',
      'Missing Class': 'Fișierul nu conține coloana de Clase',
      'Empty File': 'Fișierul este gol',
      'Load File': 'Incărcați un fișier!'
    },
    version: {
      'Version Label': 'Versiune'
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ro',
    fallbackLng: 'ro',
    interpolation: {
      escapeValue: false
    }
  });
