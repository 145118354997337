/* eslint-disable */
import { gql } from "@apollo/client";

// GET ALL COMPANIES 

const SHOW_COMPANY = gql`
    query GetCompaniesQuery {
        getCompanies {
            id
            name
            vehicle {
                id
                name
                plate
            }
        }
    }
`;

// GET COMPANY BY ID

const GET_COMPANY_BY_ID = gql`
    query GetCompanyById ($id: String!) {
        getCompanyById (id: $id){
            name
            cui
            status
            vehicle {
                id
                name
                plate
            }
        }
    }
`;

// CREATE COMPANY

const CREATE_COMPANY = gql`
mutation CreateCompanyMutation($name: String!, $cui: String!, $status: String!) {
    createCompany(name: $name, cui: $cui, status: $status) {
            id 
            name
            cui
            status
    }
}
`;

// UPDATE COMPANY

const UPDATE_COMPANY = gql`
mutation updateCompanyMutation($id: String!, $name: String!, $cui: String!, $status: String!) {
    updateCompany(id: $id, name: $name, cui: $cui, status: $status) {
            id 
    }
}
`;

// TABLE LIST COMPANIES 

const SEARCH_COMPANY = gql`
    query onCompanySearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        company(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            companies {
                id
                name
                cui
                status
            }
            count
        }
    }
`;

// GET INVENTORY HISTORY FOR COMPANY BASED ON COMPANY ID , ( TABLE LIST HISTORY ACTIONS )

const SEARCH_ACTIONS_INVENTORY_FOR_COMPANY = gql`
    query onGetAllHistoryMaterialForCompanyQuery($inventoryId: String!,$page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        getAllHistoryMaterialForCompany(inventoryId: $inventoryId, page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            materialInventoryActions {
                type
                material {
                    name
                    um
                }
                amount
                user { 
                    profile {
                        firstName
                        lastName
                    }
                }
                commentAction
                createdAt
            }
            count
        }
    }
`;

export const company = {
    query: {
        SEARCH_ACTIONS_INVENTORY_FOR_COMPANY,
        SHOW_COMPANY,
        GET_COMPANY_BY_ID,
        SEARCH_COMPANY
    },
    mutation: {
        CREATE_COMPANY,
        UPDATE_COMPANY
    },
    subscription: {
    }
}