import { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import Raisis from '../../icons/RaisisLogo';
import {
  Card,
  Button,
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography,
  CircularProgress,
  Modal,
  TextField,
  CardMedia
} from '@material-ui/core';
import DarexIcon from '../../icons/DarexLogoIcon.svg';
import PermissionWrapper from 'src/components/permissionWrapper';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ClipboardListIcon from '../../icons/ClipboardList';
import UsersIcon from '../../icons/Users';
import Archive from '../../icons/Archive';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SettingsIcon from '@material-ui/icons/Settings';
import RoomIcon from '@material-ui/icons/Room';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import BusinessIcon from '@material-ui/icons/Business';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import DnsIcon from '@material-ui/icons/Dns';
import { useQuery } from '@apollo/client';
import requests from '../../requests';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Version from '../../utils/versionsDetails.json';
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = (lang: string) => [
  {
    title: 'General',
    permissionmenu: [1, 2],
    items: [
      {
        title: lang === 'en' ? 'Auto Fleet Management' : 'Management Flota Auto',
        path: '/dashboard/vehicle-taken',
        icon: <UsersIcon fontSize="small" />,
        permissionmenu: [1, 2],
        children: [
          {
            title: lang === 'en' ? 'Prospects' : 'Preluare',
            path: '/dashboard/vehicle-taken',
            icon: <PersonPinIcon fontSize="small" />,
            permissionmenu: [1, 2],
          },
          {
            title: lang === 'en' ? 'Clients' : 'Predare',
            path: '/dashboard/vehicle-return',
            icon: <FolderSharedIcon fontSize="small" />,
            permissionmenu: [1, 2],
          },
          {
            title: lang === 'en' ? 'Locations' : 'Programare',
            path: '/dashboard/vehicle-appointment',
            icon: <RoomIcon fontSize="small" />,
            permissionmenu: [1, 2],
          }
        ]
      },
      {
        title: lang === 'en' ? 'Vehicles' : 'Vehicule',
        path: '/dashboard/vehicles',
        icon: <DriveEtaIcon fontSize="small" />,
        permissionmenu: [1],
      },
      {
        title: lang === 'en' ? 'Reports' : 'Rapoarte',
        path: '/dashboard/reports',
        icon: <ChartPieIcon fontSize="small" />,
        permissionmenu: [1],
      },
      {
        title: lang === 'en' ? 'Employee Accounts' : 'Conturi Angajați',
        path: '/dashboard/employee-accounts',
        icon: <UsersIcon fontSize="small" />,
        permissionmenu: [1],
      },
    ]
  }
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  modalSupport: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '25px',
    transform: 'translate(-50%, -50%)',
  },
}));
const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  // Support Switch active / disabled Technical Support Buttons
  const technicalSupportSwitch: boolean = true;
  //MOBILE MENU BUTTON
  const { onMobileClose, openMobile } = props;
  //USER VALIDATION
  const { user } = useAuth();
  //TRANSLATION
  const { i18n } = useTranslation();
  const { t } = useTranslation('sideBar');
  // MESSAGE APPEARS AFTER SUCCESS 
  const { enqueueSnackbar } = useSnackbar();

  //DATA
  const classes = useStyles();
  const location = useLocation();
  const [supportModal, setSupportModal] = useState<boolean>(false);

  // GET USER DATA
  const { data: userData } = useQuery(requests.user.query.ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink
              to="/"
              style={{ textDecoration: 'none' }}
            >
              <CardMedia style={{
                width: 150,
                height: 40,
              }} image={DarexIcon} title="Darex Icon" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          {userData && user ?
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'background.default',
                borderRadius: 1,
                display: 'flex',
                overflow: 'hidden',
                p: 2
              }}
            >
              <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/profile">
                {
                  userData.me.profile ?
                    <Avatar
                      src={user.avatar}
                      sx={{
                        cursor: 'pointer',
                        height: 48,
                        width: 48
                      }}
                      className={classes.purple}
                    >
                      {userData.me.profile.firstName ? userData.me.profile.firstName[0] + (userData.me.profile.lastName === '' ? '' : userData.me.profile.lastName[0]) : ''}
                    </Avatar> :
                    <CircularProgress />
                }
              </RouterLink>
              <Box sx={{ ml: 2 }}>
                {
                  userData.me.profile &&
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {userData.me.profile.firstName + ' ' + userData.me.profile.lastName}
                  </Typography>
                }
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {i18n.language === 'en' ? 'Role: ' : 'Rol: '}
                  {userData.me.role.name}
                  <Link
                    color="primary"
                    component={RouterLink}
                    to="/dashboard/account"
                  >
                    {user.plan}
                  </Link>
                </Typography>
              </Box>
            </Box> : <CircularProgress />}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections(i18n.language).map((section) => (
            <PermissionWrapper validPermissions={section.permissionmenu} key={section.title}>
              <NavSection
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            </PermissionWrapper>
          ))}
        </Box>
        <Divider />
        <Modal
          open={supportModal}
          onClose={() => setSupportModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Card className={classes.modalSupport} >
            <Formik
              initialValues={{
                observation: '',
              }}
              validationSchema={
                Yup
                  .object({
                    observation: Yup
                      .string().max(255).required(t('Table Error Observation')),
                  })
              }
              onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
                try {
                  setSubmitting(true);
                  setStatus({ success: true });
                  setSubmitting(false);
                  if (values) {
                    enqueueSnackbar(t('Msg Success Support'), {
                      anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                      },
                      variant: 'success'
                    });
                    setSupportModal(false);
                  }
                  resetForm();
                } catch (err) {
                  console.error(err);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                isSubmitting,
                handleSubmit,
                handleChange,
                touched,
                values,
              }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <Box sx={{ m: 2, p: 2 }}>
                      <Typography sx={{ mb: 2 }}>
                        {t('Table Msg Help')}
                      </Typography>
                      <Box>
                        {technicalSupportSwitch &&
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <iframe src="https://app.startinfinity.com/form/238fdef9-503f-4e0b-9993-8c5046491bce" width='100%' height='600' style={{ border: '0', boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}>
                              <Button
                                color="primary"
                                sx={{ m: 1 }}
                                variant="contained"
                                type="submit"
                              >
                                {t('Table Send')}
                              </Button>
                            </iframe>
                          </Box>
                        }
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <Typography sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {t('Table Subscription Support')} : <Typography sx={{ ml: 1 }} color={technicalSupportSwitch ? 'primary' : 'secondary'}> {technicalSupportSwitch ? t('Table Active') : t('Table Disabled')}</Typography>
                          </Typography>
                          <Box sx={{ pt: 1, pr: 4, pl: 4 }} style={{ width: "240px" }}>
                            <a href="https://raisissoftware.com/" rel="noreferrer" target="_blank" style={{ cursor: 'pointer' }}>
                              <Raisis />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Card>
        </Modal>
        <PermissionWrapper validPermissions={[1, 2]}>
          <Box sx={{ pt: 3, pl: 5, pr: 5 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {t('Table Help')}
            </Typography>
            <RouterLink
              to="/dashboard/version"
            >
              <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {t('Table Vers')} {Version[0].version}
              </Typography>
            </RouterLink>
            <Button
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => setSupportModal(true)}
            >
              {t('Table Support')}
            </Button>
          </Box>
        </PermissionWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Box sx={{ pt: 4, pr: 5, pl: 5 }} style={{ width: "90%" }}>
            <a href="https://raisissoftware.com/" rel="noreferrer" target="_blank" style={{ cursor: 'pointer' }}>
              <Raisis />
            </a>
          </Box>
        </Box>
      </Scrollbar>
    </Box >
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
