import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Raisis from '../../icons/RaisisLogo';
import Darex from '../../icons/DarexLogo.svg';
import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
  CardMedia
} from '@material-ui/core';
import {
  LoginJWT
} from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const Login: FC = () => {
  const { platform } = useAuth() as any;
  const { t } = useTranslation('login');

  return (
    <>
      <Helmet>
        <title>Darex Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '20px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 4,
            }}
          >
            <CardMedia style={{
              width: 380,
              height: 150,
            }} image={Darex} title="Darex Logo" />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 6
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'column',
                  mb: 3
                }}
              >
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {t('Table Login')}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {t('Table Title')}
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                  mb: 4,
                }}
              >
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Copyright &copy; DAREX 2021.
                </Typography>
                <Box sx={{ pt: 1, pr: 3, pl: 3 }} style={{ width: "50%" }}>
                  <a href="https://raisissoftware.com/" rel="noreferrer" target="_blank" style={{ cursor: 'pointer' }}>
                    <Raisis />
                  </a>
                </Box>
              </Box>
              {platform === 'Amplify' && (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  sx={{ mt: 1 }}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  {t('Table Forgot Password')}
                </Link>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
