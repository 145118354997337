import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './Views/Dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// DASHBOARD / HOME PAGE

const GeneralSettings = Loadable(lazy(() => import('./pages/GeneralSettings')));

// AUTHENTICATION / LOGIN PAGE

const Login = Loadable(lazy(() => import('./Views/Login/Login')));

// ACCOUNTS PAGES

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Version = Loadable(lazy(() => import('./pages/dashboard/Version')));

// EMPLOYEE PAGES 

const EmployeeAccountsEdit = Loadable(lazy(() => import('./pages/dashboard/EmployeeAccountsEdit')));
const EmployeeAccountsAdd = Loadable(lazy(() => import('./pages/dashboard/EmployeeAccountsAdd')));
const EmployeeAccountsDetails = Loadable(lazy(() => import('./pages/dashboard/EmployeeAccountsDetails')));
const EmployeeAccounts = Loadable(lazy(() => import('./Views/employeeAccounts/EmployeeAccounts')));

// VEHICLE PAGE

const Vehicles = Loadable(lazy(() => import('./Views/vehicles/Vehicles')));
const VehiclesDetails = Loadable(lazy(() => import('./pages/dashboard/VehiclesDetails')));
const VehiclesEdit = Loadable(lazy(() => import('./pages/dashboard/VehiclesEdit')));
const VehiclesAdd = Loadable(lazy(() => import('./pages/dashboard/VehiclesAdd')));

// VEHICLE ORDER ACTIONS  / VEHICLE ORDER TABLE
// APPOINTMENT, 
// TAKEN ( vehicle that are available for people to take ),
// RETURN ( vehicle that where taken , and now will be requested to be returned )

const VehiclesAppointment = Loadable(lazy(() => import('./pages/dashboard/VehiclesAppointment')));
const VehiclesTaken = Loadable(lazy(() => import('./pages/dashboard/VehiclesTaken')));
const VehiclesReturn = Loadable(lazy(() => import('./pages/dashboard/VehiclesReturn')));

const VehicleTakenList = Loadable(lazy(() => import('./Views/vehicleTaken/VehicleTakenList')));
const VehicleOrderReturnList = Loadable(lazy(() => import('./Views/vehicleOrderReturn/vehicleOrderReturnList')));
const VehicleOrderAppointment = Loadable(lazy(() => import('./Views/vehicle-appointment/VehicleOrderAppointment')))

// REPORT TABLE

const ReportList = Loadable(lazy(() => import('./Views/reports/ReportList')));

// REPORT DETAILS => vehicleOrder means Report

const VehiclesOrderDetails = Loadable(lazy(() => import('./pages/dashboard/VehiclesOrderDetails')));

// ERROR PAGE

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// ROUTES PAGE

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/general-settings',
        element: <GeneralSettings />
      },
      {
        path: 'vehicle-return',
        children: [
          {
            path: '/',
            element: <VehicleOrderReturnList />
          },
        ]
      },
      {
        path: 'vehicle-taken',
        children: [
          {
            path: '/',
            element: <VehicleTakenList />
          },
        ]
      },
      {
        path: 'vehicle-appointment',
        children: [
          {
            path: '/',
            element: <VehicleOrderAppointment />
          }
        ]
      },
      {
        path: 'employee-accounts',
        children: [
          {
            path: '/',
            element: <EmployeeAccounts />
          },
          {
            path: '/add-employee',
            element: <EmployeeAccountsAdd />
          },
          {
            path: ':id',
            element: <EmployeeAccountsDetails />
          },
          {
            path: ':id/edit',
            element: <EmployeeAccountsEdit />
          }
        ]
      },
      {
        path: 'vehicles',
        children: [
          {
            path: '/',
            element: <Vehicles />
          },
          {
            path: '/add-vehicles',
            element: <VehiclesAdd />
          },
          {
            path: ':id',
            element: <VehiclesDetails />
          },
          {
            path: ':id/edit',
            element: <VehiclesEdit />
          },
          {
            path: '/vehicles-appointment/:id',
            element: <VehiclesAppointment />
          },
          {
            path: '/vehicles-taken/:id',
            element: <VehiclesTaken />
          },
          {
            path: '/vehicles-return/:id',
            element: <VehiclesReturn />
          },
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: '/',
            element: <ReportList />
          },
          {
            path: '/report-details/:id',
            element: <VehiclesOrderDetails />
          }
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '/',
            element: <Account />
          },
        ]
      },
      {
        path: 'version',
        children: [
          {
            path: '/',
            element: <Version />
          },
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
