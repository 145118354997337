import { user } from './user';
import { company } from './company';
import { vehicle } from './vehicle';
import { order } from './order';
import { notification } from './notification';
/* eslint-disable */
const requests = {
    user,
    company,
    vehicle,
    order,
    notification
};

export default requests;