/* eslint-disable */
import { gql } from "@apollo/client";

const CREATE_VEHICLE = gql`
mutation createVehicleMutation($data: VehicleCreateInput!) {
    createVehicle(data: $data) {
            name
            plate
            status
            companyId
            VIN
            ROV
            ITP
            ASIG
            CASCO
            messageVehicleBlocked
    }
}
`;

const SEARCH_VEHICLE = gql`
    query onVehicleSearch($vehicleStatus: Boolean!, $page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        vehicles(vehicleStatus: $vehicleStatus, page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            vehicle{
                id
                name
                plate
                status
                VIN
                ROV
                ITP
                ASIG
                CASCO
                statusVehicleOrder
                messageVehicleBlocked
            }
            count
        }
    }
`;

const SEARCH_VEHICLE_AVAILABLE = gql`
    query onVehiclesAvailableSearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        vehiclesAvailable(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            vehicle{
                id
                name
                plate
                status
                VIN
                ROV
                ITP
                ASIG
                CASCO
                statusVehicleOrder
                messageVehicleBlocked
            }
            count
        }
    }
`;

const SEARCH_VEHICLE_TAKEN = gql`
    query onVehiclesTakenSearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        vehiclesTaken(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            vehicle{
                id
                name
                plate
                status
                VIN
                ROV
                ITP
                ASIG
                CASCO
                statusVehicleOrder
                messageVehicleBlocked
            }
            count
        }
    }
`;

const SEARCH_VEHICLE_APPOINTMENT = gql`
    query onVehiclesAppointmentSearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        vehiclesAppointment(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            vehicle{
                id
                name
                plate
                status
                VIN
                ROV
                ITP
                ASIG
                CASCO
                statusVehicleOrder
                messageVehicleBlocked
            }
            count
        }
    }
`;

const GET_VEHICLE_BY_ID = gql`
    query GetVehicleById ($id: String!) {
        getVehicleById (id: $id){
            name
            plate
            status
            VIN
            ROV
            ITP
            ASIG
            CASCO
            messageVehicleBlocked
        }
    }
`;

const GET_VEHICLE_RETURN_ORDER_BY_ID = gql`
    query GetVehicleReturnOrderByIdQuery ($id: String!) {
        getVehicleReturnOrderById (id: $id){
            id
            author {
                profile {
                    firstName
                    lastName
                }
            }
            vehicleId
            vehicle {
                name
                plate
            }
            purposeUsage
            driver
            vehicleStatusOrder
            startDate
            returnDate
        }
    }
`;

const GET_VEHICLE_ORDER_BY_ID = gql`
    query GetVehicleOrderByIdQuery ($id: String!) {
        getVehicleOrderById (id: $id){
            id
            author {
                profile {
                    firstName
                    lastName
                }
            }
            vehicle {
                name
                plate
                status
                VIN
            }
            purposeUsage
            driver
            vehicleStatusOrder
            startDate
            returnDate
        }
    }
`;

const GET_ALL_VEHICLES = gql`
    query GetVehicleById  {
        allVehicles {
            id
            name
            plate
            status
            VIN
            ROV
            ITP
            ASIG
            messageVehicleBlocked
        }
    }
`;

const UPDATE_VEHICLE = gql`
mutation updateVehicleMutation($data: VehicleCreateInput!) {
    updateVehicle(data: $data) {
            id 
    }
}
`;

const UPDATE_VEHICLE_ORDER = gql`
mutation updateVehicleOrderMutation($id: String!) {
    updateVehicleOrder(id: $id) {
            id 
    }
}
`;

/**
 * 
 * This method is deprecated
 * Keep here because i don't know what to change it with
 * 
 */
const UPDATE_VEHICLE_ORDER_CANCEL = gql`
mutation updateVehicleOrderCancelMutation($id: String!) {
    updateVehicleOrderCancel(id: $id) {
            id 
    }
}
`;

const UPDATE_VEHICLE_ORDER_APPOINTMENT = gql`
mutation updateVehicleOrderAppointmentMutation($id: String!, $vehicleDataId: String!) {
    updateVehicleOrderAppointment(id: $id, vehicleDataId: $vehicleDataId) {
            id 
    }
}
`;

const CREATE_VEHICLE_ORDER_APPOINTMENT = gql`
mutation createVehicleOrderAppointmentMutation($data: VehicleOrderInput!) {
    createVehicleOrderAppointment(data: $data) {
        purposeUsage
        authorId
        driver
        vehicleId
        vehicleStatusOrder
        startDate
        returnDate
    }
}
`;

const CREATE_VEHICLE_ORDER_TAKEN = gql`
mutation createVehicleOrderTakenMutation($data: VehicleOrderInput!) {
    createVehicleOrderTaken(data: $data) {
        purposeUsage
        authorId
        driver
        vehicleId
        vehicleStatusOrder
        startDate
        returnDate
    }
}
`;

const SEARCH_VEHICLE_ORDER = gql`
    query vehicleOrdersTableSearch(
        $page: Int!, 
        $rowsPer: Int!, 
        $search: String!, 
        $order: String!, 
        $crit: String!,
        $authorData: Int!,
        $vehicleData: String!,
        $typeVehicleOrder: String!,
        $startData: String,
        $returnData: String,
    ) {
        vehicleOrdersTable(
            page: $page, 
            rowsPer: $rowsPer, 
            search: $search, 
            order: $order, 
            crit: $crit,
            authorData: $authorData,
            vehicleData: $vehicleData,
            typeVehicleOrder: $typeVehicleOrder,
            startData:$startData,
            returnData:$returnData,
            ) {
            vehicleOrder{
                id
                purposeUsage
                author {
                    profile {
                        firstName
                        lastName
                    }
                }
                driver
                vehicle {
                    name
                    plate
                    VIN
                    messageVehicleBlocked
                    statusVehicleOrder
                    status
                }
                vehicleStatusOrder
                startDate
                returnDate
            }
            count
        }
    }
`;

const SEARCH_VEHICLE_ORDER_APPOINTMENT = gql`
    query vehicleOrdersAppointmentTableSearch($vehicleIdForOrder: String!, $page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        vehicleOrdersAppointmentTable(vehicleIdForOrder: $vehicleIdForOrder, page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            vehicleOrder{
                id
                purposeUsage
                author {
                    profile {
                        firstName
                        lastName
                    }
                }
                driver
                vehicle {
                    name
                    plate
                    VIN
                    messageVehicleBlocked
                    statusVehicleOrder
                    status
                }
                vehicleStatusOrder
                startDate
                returnDate
            }
            count
        }
    }
`;

export const vehicle = {
    query: {
        SEARCH_VEHICLE_ORDER_APPOINTMENT,
        GET_VEHICLE_ORDER_BY_ID,
        SEARCH_VEHICLE_APPOINTMENT,
        GET_VEHICLE_RETURN_ORDER_BY_ID,
        SEARCH_VEHICLE_TAKEN,
        SEARCH_VEHICLE_AVAILABLE,
        SEARCH_VEHICLE_ORDER,
        SEARCH_VEHICLE,
        GET_VEHICLE_BY_ID,
        GET_ALL_VEHICLES
    },
    mutation: {
        UPDATE_VEHICLE_ORDER_CANCEL,
        UPDATE_VEHICLE_ORDER_APPOINTMENT,
        UPDATE_VEHICLE_ORDER,
        CREATE_VEHICLE_ORDER_TAKEN,
        CREATE_VEHICLE_ORDER_APPOINTMENT,
        CREATE_VEHICLE,
        UPDATE_VEHICLE
    },
    subscription: {
    }
}