/* eslint-disable */
import { gql } from "@apollo/client";
//mutation

const IMPORT_ORDER_REPORTS_EXCEL_FILE = gql`
    mutation onImportOrderReportsExcelFile(
        $authorData: Int!, 
        $vehicleData: String!, 
        $typeVehicleOrder: String!, 
        $startData: String,
        $returnData: String,
        ) {
        importOrderReportsExcelFile(
            authorData: $authorData, 
            vehicleData: $vehicleData, 
            typeVehicleOrder: $typeVehicleOrder, 
            startData: $startData,
            returnData: $returnData
        )
    }
`;

export const order = {
    query: {
    },
    mutation: {
        IMPORT_ORDER_REPORTS_EXCEL_FILE,
    },
    subscription: {
    }
}
