import { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useTranslation } from 'react-i18next';

const LoginJWT: FC = (props) => {
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation('login');
  const { login } = useAuth() as any;
  const showError = useCallback((error) => {
    switch (error.submit) {
      case 'Failed to fetch':
        return t('Server Error')
      case "Cannot read property 'token' of null":
        return t('Table Error Login Pass')
      case "Cannot read property 'password' of null":
        return t('Table Error Login Email')
      default:
        return t('Went Wrong')
    }
  }, [t])
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email(t('Table Error Email'))
              .max(255)
              .required(t('Table Error Email Valid')),
            password: Yup
              .string()
              .max(255)
              .required(t('Table Error Password Valid'))
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<any> => {
        try {
          await login(values.email, values.password);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => {
        return (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              autoFocus
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label={t('Table Email')}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t('Table Password')}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {showError(errors)}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t('Table Connect')}
              </Button>
            </Box>
          </form>
        )
      }}
    </Formik>
  );
};

export default LoginJWT;
